<template>
  <div>
    <v-chart
      v-if="option != null"
      class="chart"
      :option="option"
      v-bind="attributes"
      :theme="light"
    />
    <div v-else>
      <v-skeleton-loader type="card" height="180"></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import "echarts";
import  light from "@/js/acctopus-light.json";
import VChart /*, { THEME_KEY }*/ from "vue-echarts";
import debounce from "debounce";

export default {
  components: {
    VChart,
  },
  // provide: {
  //   [THEME_KEY]: "acctopus-light",
  // },

    data() {
    return {
      option: null,
      attributes: {},

      timer: null,
      inProgress: false,
      light:light
    };
  },
  props: {
    src: {},
    baseUrl: {},
    interval: { default: 0 },
    from: {},
    to: {},
    clear: {},
  },
  mounted() {
    this.getOptions();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  watch: {
    clear() {
      this.option = null;
      this.attributes = {};
    },
    from() {
      //  this.option = null;
      //this.attributes = {};
      this.getOptions();
    },
    to() {
      // this.option = null;

      // this.attributes = {};
      this.getOptions();
    },
  },
  methods: {
    getOptions() {
      debounce(this.getOptionsCall, 500)(this);
    },
    getOptionsCall(caller) {
      if (!caller.inProgress) {
        caller.inProgress = true;
        clearTimeout(caller.timer);

        caller.frameworkAxiosRequest({
          method: 'post',
          url: this.baseUrl,
          data: {
            requestType: 'ajax',
            src: this.src,
            from: this.from,
            to: this.to,
          },
        })
            .then(function (post) {
            caller.inProgress = false;
            try {
              caller.option = post.data.result.json;
                caller.attributes = {  };
              caller.$nextTick(() => {
                caller.attributes = { autoresize: true };
                if (caller.interval > 0) {
                  caller.timer = setTimeout(function () {
                    caller.getOptions();
                  }, caller.interval);
                }
              });
            } catch (error) {
              console.log(error);
            }
          })
          .catch((error) => {
            console.log(error);
            caller.inProgress = false;
          });
      }
    },
  },

};
</script>

<style scoped>
.chart {
  height: 180px;
}
</style>