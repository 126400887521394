<template>
  <div>
        <v-row no-gutters>
            <template v-for="(value,index) in Math.ceil(Object.keys(graphs).length )">
                <v-col :key="value" cols="6">
                    <v-card
                            outlined
                            tile
                            elevation="0"
                            style="border-color: white"
                    >
                    <echart
                            :src="graphs[index] + '/' + name"
                            baseUrl="serve.php?f=configuration&f2=explorerGraphs"
                            :from="from"
                            :to="to"
                            :clear="clear"
                    ></echart>
                    </v-card>
                 </v-col>
                <v-responsive
                        v-if="value%2==0"
                        :key="`width-${value}`"
                        width="100%"
                ></v-responsive>
            </template>
        </v-row>
    </div>
</template>
<script>
import echart from "@/commonComponents/echart.vue";
export default {
  components: {
   echart
  },
  props: ["name", "upTime", "from", "to", "clear"],
  data() {
    return {
      graphs: [
        "explorer-system/ram",
        "explorer-system/cpu",
        "explorer-system/swap",
        "explorer-system/load",
        "explorer-system/processes",
        "explorer-system/temperature",
        "explorer-system/network-io",
        "explorer-system/hdd",
      ],
    };
  },
};
</script>


