var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('fields',_vm._g({attrs:{"result":_vm.result},scopedSlots:_vm._u([{key:"default",fn:function(fieldsProps){return [_c('tabsStructure',{attrs:{"is-valid":_vm.isValid,"normal":_vm.normal,"tabNames":_vm.tabList,"tabsPrefix":"explorerViewContainer"},on:{"changeIsValid":function($event){return _vm.$emit('changeIsValid', $event)}},scopedSlots:_vm._u([{key:"tabsContent",fn:function(data){return [_c('div',{staticStyle:{"min-height":"675px"}},[(data.item.index === 'statistic')?_c('div',{staticStyle:{"height":"auto"}},[_c('v-row',{staticClass:"justify-end"},[_c('v-col',{staticClass:"ml-3",staticStyle:{"min-width":"200px"},attrs:{"cols":"1"}},[_c('datePickerExtend',{attrs:{"interval":_vm.refreshInterval},on:{"updateDates":_vm.updateDates},model:{value:(_vm.info.data.timerange),callback:function ($$v) {_vm.$set(_vm.info.data, "timerange", $$v)},expression:"info.data.timerange"}})],1),_c('v-col',{staticStyle:{"max-width":"200px"},attrs:{"id":"dashboardRefreshInterval_container"}},[_c('associatedOption',{attrs:{"fieldAttributes":{
                      type: 'select',
                      associatedOption: [
                        [0, 'off'],
                        [10, '10s'],
                        [30, '30s'],
                        [60, '1m'],
                        [120, '2m'],
                        [300, '5m'],
                        [600, '10m'],
                      ],
                      setEmpty: false,
                      sort: false,
                      name: 'Refresh',
                      ignoreEmptyAdd: true,
                    },"hideDetails":true,"type":"select"},model:{value:(_vm.refreshInterval),callback:function ($$v) {_vm.refreshInterval=$$v},expression:"refreshInterval"}})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticStyle:{"background-color":"#f2f3f4"}},[_c('explorerStatistics',{attrs:{"clear":_vm.manuallyChanged,"from":_vm.info.data.begin,"name":_vm.result.valueLine.c_name,"to":_vm.info.data.end,"upTime":_vm.result.valueLine.upTime}})],1)])],1)],1):_vm._e(),(data.item.index === 'configuration')?_c('ExplorerConfiguration',_vm._g({attrs:{"fields-props":fieldsProps,"isValid":_vm.isValid,"tags":_vm.result.valueLine['c_tags']},on:{"input":_vm.checkInput,"change-is-valid":function($event){return _vm.$emit('change-is-valid', $event)}}},_vm.$listeners)):_vm._e(),(data.item.index === 'systemInfo')?_c('explorerSystemInfo',{attrs:{"explorer-name":_vm.valueLine.c_name}}):_vm._e(),(data.item.index === 'systemLog')?_c('statusLog',{attrs:{"result":_vm.result,"valueLine":_vm.valueLine}}):_vm._e(),(data.item.index === 'lastTestResult')?_c('explorerLastTests',{attrs:{"result":_vm.result,"valueLine":_vm.valueLine}}):_vm._e()],1)]}}],null,true)})]}}])},_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }