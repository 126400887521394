<template>
  <div>
    <atable v-if="tableResult != null" :result="tableResult"></atable>
  </div>
</template>
<script>
import atable from '@/commonComponents/dynamic/table.vue';

export default {
  components: {
    atable,
  },
  props: {
    explorerName: String,
  },
  data() {
    return {
      tableResult: null,
    };
  },
  created() {
    let caller = this;
    this.frameworkAxiosRequest({
      method: 'post',
      url: 'serve.php?f=testing&f2=explorerTestsTabs&name=' + this.explorerName,
      data: {requestType: 'ajax'},
    }).then(function (response) {
      //handle success
      if (typeof response.data.result != 'undefined') {
        caller.tableResult = response.data.result;
        caller.$store.commit('tableChanged', caller.tableResult.table);
      }
    }).catch(function (response) {
      //handle error
      console.log(response);
    });
  },
};
</script>