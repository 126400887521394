<template>
  <div>
    <fields :result="result" v-on="$listeners">
      <template v-slot:default="fieldsProps">
        <tabsStructure
            :is-valid="isValid"
            :normal="normal"
            :tabNames="tabList"
            tabsPrefix="explorerViewContainer"
            @changeIsValid="$emit('changeIsValid', $event)">
          <template v-slot:tabsContent="data">
            <div style="min-height: 675px">
              <div v-if="data.item.index === 'statistic'" style="height: auto">
                <v-row class="justify-end">
                  <v-col class="ml-3" cols="1" style="min-width: 200px">
                    <datePickerExtend
                        v-model="info.data.timerange" :interval="refreshInterval" @updateDates="updateDates"/>
                  </v-col>
                  <v-col
                      id="dashboardRefreshInterval_container" style="max-width: 200px">
                    <associatedOption
                        v-model="refreshInterval" :fieldAttributes="{
                        type: 'select',
                        associatedOption: [
                          [0, 'off'],
                          [10, '10s'],
                          [30, '30s'],
                          [60, '1m'],
                          [120, '2m'],
                          [300, '5m'],
                          [600, '10m'],
                        ],
                        setEmpty: false,
                        sort: false,
                        name: 'Refresh',
                        ignoreEmptyAdd: true,
                      }" :hideDetails="true" type="select"/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div style="background-color: #f2f3f4">
                      <explorerStatistics
                          :clear="manuallyChanged"
                          :from="info.data.begin"
                          :name="result.valueLine.c_name"
                          :to="info.data.end"
                          :upTime="result.valueLine.upTime"/>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <ExplorerConfiguration
                  v-if="data.item.index === 'configuration'"
                  :fields-props="fieldsProps"
                  :isValid="isValid"
                  :tags="result.valueLine['c_tags']"
                  @input="checkInput"
                  v-on="$listeners"
                  @change-is-valid="$emit('change-is-valid', $event)"/>
              <!--              <explorerConfiguration-->
              <!--                  v-if="data.item.index === 'configuration'"-->
              <!--                  :result="fieldsProps.result"-->
              <!--                  :valueLine="valueLine"-->
              <!--                  v-on="$listeners"-->
              <!--                  @field-value-changed="checkInput(...arguments)"/>-->
              <explorerSystemInfo
                  v-if="data.item.index === 'systemInfo'" :explorer-name="valueLine.c_name"/>
              <statusLog
                  v-if="data.item.index === 'systemLog'" :result="result" :valueLine="valueLine"/>
              <explorerLastTests
                  v-if="data.item.index === 'lastTestResult'" :result="result" :valueLine="valueLine"/>
            </div>
          </template>
        </tabsStructure>
      </template>
    </fields>
  </div>
</template>
<script>
import associatedOption from '@/commonComponents/associatedOption.vue';
import datePickerExtend from '@/commonComponents/datePickerExtend.vue';
import explorerStatistics from '@/components/legacy/explorerStatistics.vue';
import fields from '@/commonComponents/dynamic/fields.vue';
import tabsStructure from '@/components/legacy/tabsStructure.vue';
import explorerLastTests from '@/components/legacy/explorerLastTests.vue';
import ExplorerConfiguration from '@/components/specific/ExplorerConfiguration';
import explorerSystemInfo from '@/components/legacy/explorerSystemInfo.vue';
import statusLog from '@/components/legacy/statusLog.vue';

export default {
  components: {
    fields,
    explorerStatistics,
    tabsStructure,
    datePickerExtend,
    explorerLastTests,
    ExplorerConfiguration,
    explorerSystemInfo,
    associatedOption,
    statusLog,
  },
  props: ['result', 'isValid'],
  data() {
    return {
      tabList: [
        {
          index: 'statistic',
          name: 'Statistic',
          disabled: false,
        },
        {
          index: 'configuration',
          name: 'configuration',
          disabled: false,
        },
        {
          index: 'systemInfo',
          name: 'system information',
          disabled: false,
        },
        {
          index: 'systemLog',
          name: 'Logbook',
          disabled: false,
        },
        {
          index: 'logging',
          name: 'logging',
          disabled: true,
        },
        {
          index: 'lastTestResult',
          name: 'last test results',
          disabled: false,
        },
      ],
      manuallyChanged: false,
      pickerOptions: {
        period: '0.084',
      },
      normal: true,
      info: {
        data: {
          timerange: 'Last 24 hours',
          begin: 'now-24h',
          end: 'now',
        },
        meta: {
          refreshInterval: {
            name: 'Refresh Interval',
            type: 'select',
            possibleValues: [
              'off',
              '10 s',
              '30 s',
              '1 min',
              '2 min',
              '5 min',
              '10 min',
            ],
          },
        },
      },
      refreshInterval: '0',
      customPeriodDialog: false,
    };
  },
  watch: {},
  created() {
    let now = Math.floor(Date.now() / 1000);
    this.$set(this.pickerOptions, 'untilTime', now);
    let adayBefore = now - 86400;
    this.$set(this.pickerOptions, 'fromTime', adayBefore);
    console.log(this.$listeners);
  },
  computed: {
    explorerSwitchLabel() {
      console.log(this.result.valueLine);
      console.log(this.explorerSwitch);
      let label = 'Deactivated';
      if (this.explorerSwitch) {
        label = 'Activated';
      }
      return label;
    },
    fields() {
      return this.result.fields;
    },
    valueLine() {
      return this.result.valueLine;
    },
  },
  methods: {
    checkInput(value) {
      if (typeof this.result.valueLine == 'undefined') {
        this.$set(this.result, 'valueLine', {});
      }
      this.$set(this.result.valueLine, 'explorerConfiguration', value);
      this.$emit('dialog-result-changed', this.result);
      this.$emit('revalidate');
    },

    updateDates(from, to) {
      this.$set(this.info.data, 'begin', from);
      this.$set(this.info.data, 'end', to);
      this.customPeriodDialog = true;

      this.$nextTick(() => {
        this.customPeriodDialog = false;
      });
      //Need to think about it!
      if (this.period !== 'custom' && this.interval !== 0) {
        let now = Math.floor(Date.now() / 1000);
        this.$set(this.pickerOptions, 'untilTime', now);
        let fromTime = now - this.period * 86400;
        this.$set(this.pickerOptions, 'fromTime', fromTime);
        this.$emit('options-changed', this.pickerOptions);
      }
    },
  },
};
</script>
<style>
#explorersc_tags_container .v-input__slot {
  min-height: 108px !important;
  display: inline;
}

#explorersc_tags_container .v-select__selections {
  min-height: 140px !important;
  display: flow-root !important;
}

#explorersc_tags_container .v-chip {
  margin: 4px 2px;
}

#systemInfo {
  max-height: 110px;
  overflow-y: scroll;
}
</style>